/*** affiliations banners***/
footer .affiliations.contentwrap {
    padding: 0 0 4vw 0;
}
footer .banners-affiliations .banner-stage {
    padding-top: 0px;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
}
footer .banner-stage::after {
    content:'';
    display: none;
}
footer .banners-affiliations .banner {
    width: 200px;
    height: 100px;
    padding: 0px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 3vw 1vw 0;
}
footer .banners-affiliations .banner-content,
footer .banners-affiliations .banner-navigation {
    display: none;
}
/*============ footer ============*/
footer .footer-bottom {
    background-color: $dark-blue;
    background-size:cover;
    color:#fff;
    padding: 2vw 0;
}
footer a.logo {
    width: 150px;
    padding-right: 50px;
}
.footer-contact {
    width: 100%;
}
.footer-contact a {
    text-decoration: none;
    color: #FFF;
}
/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}
/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (max-width: 960px) {
   .footer-bottom .contentwrap.flex.row {
        justify-content: center;
        align-items: center;
   }
   .footer-contact {
        text-align: center;
   }
}
@media only screen and (max-width: 760px) {
   footer a.logo {
        padding-right: 0;
   }
   .footer-bottom .flex.row {
        flex-direction: column;
        align-items: center;
   }
   footer .footer-bottom {
        padding-top: 8vw;
   }
}