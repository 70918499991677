/*============ Forms ============*/
div.pop-up {
    position: fixed;
    z-index: 10000;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #ffffffde;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    max-height: 100vh;
    padding-top: 85vh;
    padding-bottom: 10vh;
}
div.pop-up#applicationForm {
    padding-top: 0;
}
.form {
    position: relative;
    max-width: 1000px;
    background-color: $grey;
    border-radius: 10px;
}
.form p,
.form ul,
label {
    font-size: 14px;
}
.form .bold,
.form legend {
    font-weight: 700;
    font-size: 16px;
}
div.form-sent.pop-up > .form {
    min-height: 400px;
    max-width: 400px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 5vw;
}
div.form-sent.pop-up > .form h2 {
    font-size: 40px;
}
div.form-sent.pop-up > .form p {
    font-size: 25px;
}
.form .close,
.form #close {
    position:absolute;
    top: 20px;
    right: 20px;
    z-index:70;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.close > span,
#close > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #fff;
    margin: 6px 0;
    transition: all 0.5s ease;
}
#close > span {
    background-color: $dark-blue;
}
.close span.one,
#close span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.close span.two,
#close span.two {
     -webkit-transform: rotate(45deg) translate(0px, 0px);
     transform: rotate(45deg) translate(0px, 0px);
}
.form h1 {
    position: relative;
    font-size: 30px;
    color: #fff;
    background-color: $blue;
    padding: 20px 50px;
    border-radius: 10px 10px 0 0;
}
form,
.form > p {
    padding: 0 50px;
}
.form > ul {
    padding: 0 20px 0 100px;
}
form {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;
}
legend {
    margin-bottom:12px;
    padding: 15px 0 0 0;
}
.services legend {
    margin-bottom: 20px;
}
.services .medics_no {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin-top: 0;
    input{
        width: 35%;
        min-width: 250px;
        @media only screen and (max-width: 760px)
        {
            width: 100%;
        }
    }
}
form p {
    position: relative;
    margin: 12px 0;
}
label,
label.float,
input {
    min-height: 35px;
    top:8px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
    min-height: 10px;
}
label#services_requested-error {
    position: absolute;
    right: 0;
    top: -20px;
    left: 0;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    top:-16px;
    font-size: 0.75em;
}
form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}
.contact .flex p {
    width: 48%;
}
.form p.full-width {
    width: 100%;
}
.event .date,
.event .time {
    width: 35%;
}
.event .date {
    margin-right: 20px;
}
#bookingForm .disclaimer .container,
#trainingForm .disclaimer .container {
    width: 43.5%;
}

/* checkboxes */

fieldset.services .container {
    width: 25%;
}
#trainingForm fieldset.services .container {
    min-width: 25%;
    flex:1;
}
fieldset.services .container.other-service {
    width: 10%;
    margin-top:-10px;
}
p#quote_other,
p#otherBooking {
    width: 80%;
    margin-top:0;
}
/* custom checkboxes */

.container {
    display: block;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 0;
}
.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid $dark-blue;
}
.training-form .checkmark {
    margin-top: -7px;
}
.container:hover input ~ .checkmark {
    background-color: $dark-blue;
}
.container input:checked ~ .checkmark {
    background-color: #fff;
    border: 2px solid $dark-blue;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.container input:checked ~ .checkmark:after {
    display: block;
}
.container .checkmark:after {
 	top: 9px;
 	left: 9px;
 	width: 8px;
 	height: 8px;
 	border-radius: 50%;
 	background: $dark-blue;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    div.pop-up {
        padding:0;
    }
    .form {
        position: absolute;
        top: 0;
        border-radius:0;
        min-height:100vh;
    }
    .form h1 {
        border-radius:0;
    }
}
@media only screen and (max-width: 760px) {
    .contact .flex p,
    fieldset.services .container,
    #bookingForm .disclaimer .container,
    #trainingForm .disclaimer .container,
    p#other,
    p#otherBooking {
        width: 100%;
    }
    #trainingForm fieldset.services .container {
        min-width: 90%;
    }
    .event .date, .event .time {
        width: 100%;
        margin-right:0;
    }
    .form h1 {
        padding: 20px 70px 20px 20px;
    }
    form, .form > p {
        padding: 0 20px;
    }
    form {
        padding-bottom: 50px;
    }
    .form > ul {
        padding-left: 50px;
    }
}