/*============ header ============*/
header.top {
    font-family: $font;
    font-size:14px;
    position: absolute;
    top:0;
    width: 100vw;
    height: $banner-height;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
}
header.top .contentwrap {
    position: unset;
}
h1.logo {
    position: absolute;
    top: 4vw;
    width: 100vw;
    left: 0;
    transition: all 0.8s ease;
}
h1.logo figure {
    position: relative;
    width: 250px;
	margin: 0 auto;
    border-radius:50%;
	overflow: hidden;
    transition: all 0.5s ease;
}
h1.logo figure:hover {
    width: 350px;
}
/* Shine */
h1.logo figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
h1.logo figure:hover::before {
	-webkit-animation: shine .95s;
	animation: shine .95s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}
/*============ splash banner ============*/
.banners-home .banner-stage {
    height: $banner-height;
    position:relative;
}
.banners-home .banner-stage::before {
    content: '';
    background-color: $blue-overlay;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:10;
}
.banners-home .banner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:block;
    background-position:center;
    background-size:cover;
}
.banners-home .banner-navigation,
.banner .banner-name {
    display:none;
}
.banner-title {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 65px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 10;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (min-width: 1460px) {
    h1.logo {
        position: fixed;
    }
    h1.logo.fixed {
        width: 150px;
    }
    h1.logo.fixed {
        top:20px;
        left:20px;
    }
    h1.logo.fixed figure,
    h1.logo.fixed figure:hover {
        width: 150px;
    }
}
@media only screen and (max-width: 960px) {
    h1.logo {
        top: 2vw;
    }
    h1.logo figure {
        width: 35vw;
    }
    .banners-home .banner-stage,
    header.top {
        height: 40vw;
    }
}
@media only screen and (max-width: 760px) {


}